@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: fit-content;
  background-color: rgba(var.$gray, 0.8);
  backdrop-filter: blur(10px);
  transform: translateY(-200%);
  transition: all 0.3s;
  overflow: auto;
  padding-top: 73px;

  @include mix.vp-1024 {
    display: block;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 16px 32px;
  gap: 20px;
}

.opened {
  transform: translateY(0%);
}

.link {
  @include mix.text(18, 400, 18);
  color: var.$black;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: center;
}
